/* borderbox for all gravite_widget and childs */
#gravite_widget_button_container *,
#gravite_widget_modal_container * {
  box-sizing: border-box !important;
}

/* Styles pour le bouton */
#gravite_widget_button_container {
  position: fixed !important;
  top: 30% !important;
  right: 0 !important;
  transform: rotate(-90deg) translateX(50%);
  transform-origin: bottom right;
  z-index: 9999 !important;
  transition: transform 0.3s ease-in-out;
}

#gravite_widget_button_container.translate {
  transform: rotate(-90deg) translate(50%, 100%);
}

#gravite_widget_button_container button {
  background-color: #5555d8;
  color: white;
  border-radius: 8px 8px 0 0 !important;
  border: none !important;
  cursor: pointer !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 3px 12px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  gap: 10px !important;
}

#gravite_widget_button_container button .button-icon {
  transform: rotate(90deg);
}

/* Styles pour la modale */
#gravite_widget_modal_container {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent !important;
  display: none;
  z-index: 9999 !important;
}

#gravite_widget_modal {
  background-color: #fff !important;
  position: absolute !important;
  right: 16px;
  top: 30%;
  transform: translateX(calc(100% + 50px)) translateY(-10%);
  transition: transform 0.3s ease-in-out;

  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;

  width: 317px !important;
  height: 183px !important;

  border: 1px solid #e4e7ec !important;
  border-radius: 8px !important;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06) !important;
}

#gravite_widget_modal.translate {
  transform: translateX(0) translateY(-10%);
}

:host * {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

:host .close-button {
  position: absolute;
  right: 16px;
  top: -10px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
  width: 27px;
}

:host .powered-by-container {
  position: absolute;
  bottom: 3px;
  left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

:host .powered-by-container .gravite-logo {
  height: 15px;
}

:host .powered-by-container .gravite-logo svg {
  vertical-align: baseline;
}

:host .powered-by {
  font-size: 10px;
  line-height: 17px;
  color: #a0aec0;
}
:host .powered-by a {
  font-size: 10px;
  line-height: 17px;
  color: #a0aec0;
  text-decoration: underline;
}

#gravite_widget_modal_content {
  width: 100%;
  height: 100%;
  padding: 12px 16px;
  overflow: hidden;
  transition: transform 3s ease-in-out;
}
/* SATISFACTION STEP */
#gravite_widget_modal_content .title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

#gravite_widget_modal_content .satisfaction-select {
  width: 100%;
  transition: all 0.3s ease-in-out;
  transform: translateY(70%);
}

#gravite_widget_modal_content .satisfaction-select .flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 12px;
}

#gravite_widget_modal_content .satisfaction-select .flex-container .flex-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 45px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

#gravite_widget_modal_content
  .satisfaction-select
  .flex-container
  .flex-item
  > div:first-child {
  font-size: 30px;
  line-height: 38px;
  font-weight: 400;
}

#gravite_widget_modal_content
  .satisfaction-select
  .flex-container
  .flex-item:hover
  > div:last-child {
  visibility: visible;
}

#gravite_widget_modal_content
  .satisfaction-select
  .flex-container
  .flex-item
  > div:last-child {
  visibility: hidden;
  font-size: 12px;
  color: #a0aec0;
}

/* FEEDBACK STEP */
#gravite_widget_modal_content .feedback-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 75%;
  position: relative;
}

#gravite_widget_modal_content .feedback-step .feedback-textarea {
  width: 281px;
  height: 98px;
  resize: none;
  padding: 10px 14px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-top: 5px;
}

#gravite_widget_modal_content .feedback-step .submit-button {
  color: #ffffff;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 5px;
  padding: 5px 14px;
  border-radius: 8px;
  opacity: 1;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

#gravite_widget_modal_content .feedback-step .submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* CONTACT STEP */
#gravite_widget_modal_content .contact-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75%;
  position: relative;
}

#gravite_widget_modal_content .contact-step .input-field {
  width: 281px;
  height: 40px;
  padding: 0 3px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-top: 5px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#gravite_widget_modal_content .contact-step .input-field input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  border: none;
}

#gravite_widget_modal_content .contact-step .input-field input::placeholder {
  font-size: 12px;
  font-weight: 400;
}

#gravite_widget_modal_content .contact-step .button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#gravite_widget_modal_content .contact-step .skip-button,
#gravite_widget_modal_content .contact-step .send-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px 14px;
  border-radius: 8px;
}

#gravite_widget_modal_content .contact-step .skip-button {
  color: #98a2b3;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

#gravite_widget_modal_content .contact-step .skip-button:active {
  box-shadow: none;
}

#gravite_widget_modal_content .contact-step .send-button {
  color: #ffffff;
  background-color: #5555d8;
}
#gravite_widget_modal_content .contact-step .send-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* THANK STEP */

#gravite_widget_modal_content #thanks-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 100%;
}

#gravite_widget_modal_content #thanks-step .thanks-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
}
